import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import loadable from 'ui-component/Loadable';

const ResetPasswordPage = loadable(
  lazy(() => import('pages/auth-page/ResetPassword')),
);
const PrivacyPolicyPage = loadable(
  lazy(() => import('pages/privacy-page/PrivacyPolicy')),
);
const TermsOfService = loadable(
  lazy(() => import('pages/terms-of-service-page/TermsOfService')),
);
const FaqPage = loadable(lazy(() => import('pages/faq-page/FaqPage')));
const FaqRatingPage = loadable(
  lazy(() => import('pages/faq-page/FaqPageRate')),
);
const FaqBadgesPage = loadable(
  lazy(() => import('pages/faq-page/FaqPageBadges')),
);
const DeletePage = loadable(lazy(() => import('pages/faq-page/FaqPageDelete')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: '/terms-of-service',
      element: <TermsOfService />,
    },
    {
      path: '/faq',
      element: <FaqPage />,
    },
    {
      path: '/faq/rating',
      element: <FaqRatingPage />,
    },
    {
      path: '/faq/badges',
      element: <FaqBadgesPage />,
    },
    {
      path: '/delete',
      element: <DeletePage />,
    },
  ],
};

export default MainRoutes;
